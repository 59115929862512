import React from "react"
import { Box, BoxProps } from "@mui/material"
import { SxProps, Theme } from "@mui/material/styles"

type PortletProps = BoxProps & {
  style?: SxProps<Theme>
  children: React.ReactNode
}

const Portlet = (props: PortletProps) => {
  const { children, style, ...rest } = props

  return (
    <Box
      {...rest}
      padding="32px 24px"
      boxShadow={1}
      borderRadius="16px"
      sx={{
        backgroundColor: "white",
        ...style,
      }}
    >
      {children}
    </Box>
  )
}

export default Portlet
