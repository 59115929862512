const colors = {
  palette: {
    primary: "rgb(19,173,228)",
    secondary: "#2030ab",

    confirm: "#5fe09a",
    error: "#d9534f",
    warning: "#F1C34E",
    info: "#188FFB",

    link: "#2030ab",
    background: "#f8f9fa",
    dark: ""
  },
  grayScale: {
    1: "#212429",
    2: "#DDE2E5",
    3: "#696f76",
    4: "#495057",
    5: "#969696",
    6: "#ABABAB",
    7: "#BFBFBF",
    8: "#D0D0D0",
    9: "#E1E1E1",
    10: "#F0F0F0",
    11: "#FFFFFF"
  }
}

export default colors
