import { Grid } from "@mui/material"

import HomePage from "~pages/home"

const App = () => (
  <Grid container justifyContent="center">
    <HomePage />
  </Grid>
)

export default App
