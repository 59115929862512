import React, { useRef, useEffect } from "react"

// Libraries
import { Transformer, Text, Group } from "react-konva"
import { RenderImageProps } from "~interfaces/canvas.interface"
import { IPosition } from "~interfaces/frameGenerator.interface"

interface TransformableTextProps {
  shapeProps?: RenderImageProps
  isSelected: boolean

  fontStyle: string
  fontSize: number
  textValue: string

  transformablePosition: IPosition

  fontFamily?: string
  fontColor?: string
  alignment?: string

  onSelect: () => void
  onChange: (value: RenderImageProps) => void
}

const TransformableText = (props: TransformableTextProps) => {
  const {
    shapeProps,
    isSelected,

    textValue,

    fontFamily,
    fontStyle,
    fontColor,
    alignment,
    fontSize,

    transformablePosition,

    onSelect,
    onChange,
  } = props

  const shapeRef = useRef<any>()
  const trRef = useRef<any>()

  useEffect(() => {
    if (isSelected && trRef.current && shapeRef.current) {
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  return (
    <>
      <Group
        id={shapeProps?.id}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...shapeProps}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          })
        }}
        onTransformEnd={() => {
          if (shapeRef.current) {
            const node = shapeRef.current
            onChange({
              ...shapeProps,
              x: node.x(),
              y: node.y(),
            })
          }
        }}
      >
        <Text
          x={transformablePosition?.x}
          y={transformablePosition?.y}
          text={textValue || ""}
          align={alignment || "left"}
          verticalAlign="middle"
          fill={fontColor || "black"}
          fontSize={fontSize || 12}
          fontFamily={fontFamily || "Poppins"}
          fontStyle={fontStyle}
        />
      </Group>
      {isSelected && (
        <Transformer
          ref={trRef}
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right",
          ]}
          boundBoxFunc={(oldBox, newBox) =>
            newBox.width < 5 || newBox.height < 5 ? oldBox : newBox
          }
        />
      )}
    </>
  )
}

export default TransformableText
