import React, { useState, forwardRef } from "react"
import {
  Typography,
  Dialog as DialogMui,
  Button,
  DialogActions as DialogActionsMui,
  Grid,
  DialogContent,
  IconButton,
  CircularProgress,
  Slide,
  createStyles,
  Theme,
  DialogTitle as MuiDialogTitle,
  DialogProps,
} from "@mui/material"
import { WithStyles } from "@mui/styles"
import { TransitionProps } from "@mui/material/transitions"

import { Close as CloseIcon } from "@mui/icons-material"

import { Divider } from "~elements/index"
import colors from "~styles/colors"

type DialogActionsProps = {
  children?: React.ReactNode
  isOpen: boolean

  title?: string
  isFullScreen?: boolean
  maxWidth?: DialogProps["maxWidth"]

  cancelButtonText?: string
  onCancel?: () => void

  okButtonText?: string
  onOk?: (() => Promise<void>) | (() => void)

  onClose: () => void
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...(props as any)} />
})

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = (props: Partial<DialogTitleProps>) => {
  const { children, onClose } = props
  return (
    <MuiDialogTitle>
      <Typography color="primary" variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            color: colors.grayScale[4],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

const DialogActions = (props: DialogActionsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    isOpen,
    title,
    children,
    isFullScreen,
    maxWidth,

    cancelButtonText,
    onCancel,

    okButtonText,
    onOk,

    onClose,
  } = props

  const handleClose = () => {
    onClose()
  }

  const handleOk = async () => {
    setIsLoading(true)

    onOk && (await onOk())

    setIsLoading(false)
  }

  const handleCancel = () => {
    onCancel && onCancel()

    handleClose()
  }

  return (
    <DialogMui
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          ...(!isFullScreen && {
            borderRadius: "8px",
            padding: "8px",
          }),
          overflowX: "hidden",
        },
      }}
      maxWidth={maxWidth || "sm"}
      fullScreen={isFullScreen}
      TransitionComponent={Transition}
    >
      <DialogTitle id="dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>

      <DialogContent
        sx={{
          padding: {
            xs: 0,
            sm: "20px 24px",
          },
        }}
      >
        {children}
      </DialogContent>

      {(onCancel || cancelButtonText || onOk || okButtonText) && (
        <>
          <Divider size={1.5} orientation="horizontal" />

          <DialogActionsMui>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                {(onCancel || cancelButtonText) && (
                  <Button onClick={handleCancel}>
                    {cancelButtonText || "Cancelar"}
                  </Button>
                )}
              </Grid>

              <Grid item>
                {(onOk || okButtonText) && (
                  <Button
                    onClick={handleOk}
                    color="primary"
                    variant="contained"
                    disabled={isLoading}
                    endIcon={
                      isLoading && (
                        <CircularProgress size={16} color="inherit" />
                      )
                    }
                  >
                    {okButtonText || "OK"}
                  </Button>
                )}
              </Grid>
            </Grid>
          </DialogActionsMui>
        </>
      )}
    </DialogMui>
  )
}

export default DialogActions
