import { Box } from "@mui/material"

type DividerProps = {
  orientation: "horizontal" | "vertical"
  size: number
}

const Divider = (props: DividerProps) => {
  const { orientation, size } = props
  const customProps = {
    ...(orientation === "horizontal"
      ? { height: 8 * size, width: "100%" }
      : {}),
    ...(orientation === "vertical" ? { height: "auto", width: 8 * size } : {}),
    border: "none",
  }

  return (
    <Box
      sx={{
        background: "transparent",
      }}
      style={{ ...customProps }}
    />
  )
}

export default Divider
