import { useRef, useState } from "react"
import { Box, Grid, Typography } from "@mui/material"
import { Upload as UploadIcon } from "@mui/icons-material"

import colors from "~styles/colors"
import { ImageUtil } from "~utils/index"
import { ImageEditorProps } from "~interfaces/frameGenerator.interface"

interface InputImageProps {
  onChange: (data: ImageEditorProps) => void
}

const defaultBackground = ImageUtil.backgroundTransparentUrl

const InputImage = (props: InputImageProps) => {
  const { onChange } = props

  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedImage, setSelectedImage] = useState<string | null>()

  const handleUploaded = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files as FileList

    if (files.length > 0) {
      const fileUrl = URL.createObjectURL(files[0]) as string

      const i = new Image()
      i.onload = function () {
       
        onChange({
          url: fileUrl,
          height: i.height,
          width: i.width,
        })
      }
      i.src = fileUrl

      setSelectedImage(fileUrl)
    }
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      width={280}
      height={280}
      sx={{
        cursor: "pointer",
        background: defaultBackground,
        transition: "background 0.8s",
        border: "1px solid transparent",
        "&:hover": {
          opacity: 0.8,
          border: `1px solid ${colors.palette.primary}`,
        },
      }}
      onClick={(e) => fileInputRef.current && fileInputRef.current.click()}
    >
      <input
        id="input-file-image"
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleUploaded}
      />

      {selectedImage ? (
        <Grid item>
          <Box
            component="img"
            sx={{
              width: "80%",
              height: "auto",
              margin: "0 auto",
              display: "flex",
              maxWidth: "250px",
              maxHeight: "250px",
            }}
            src={selectedImage}
          />
        </Grid>
      ) : (
        <Grid item>
          <Box>
            <UploadIcon
              sx={{
                fontSize: "90px",
              }}
            />

            <Typography textAlign="center">Clique aqui</Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default InputImage
