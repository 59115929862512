import { useRef, useState } from "react"
import { Grid, Typography, Button, Box } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import useImage from "use-image"

import CanvasStage from "~elements/canvas/stage.component"

import { Divider, Portlet } from "~elements/index"
import { ImageUtil } from "~utils/index"
import { IFrameOption } from "~interfaces/frameGenerator.interface"

import FrameGeneratorProvider, {
  useFrameGeneratorStore,
} from "~stores/frameGenerator.store"

import FrameGeneratorEditor from "./editor.component"
import colors from "~styles/colors"

interface FrameGeneratorProps {
  frameOptions: Array<IFrameOption>

  canInsertText?: boolean
}

const defaultBackground = ImageUtil.backgroundTransparentUrl

const FrameGenerator = (data: FrameGeneratorProps) => {
  const { frameOptions, canInsertText } = data

  const theme = useTheme()
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"))

  const [selectedFrame, setSelectedFrame] = useState<IFrameOption | null>(
    frameOptions[0] || null
  )
  const { editorData } = useFrameGeneratorStore()

  const stageRef = useRef<HTMLCanvasElement>(null)
  const [viewPreview, setViewPreview] = useState<boolean>(false)
  const [frameImg] = useImage(selectedFrame?.url || "", "anonymous")
  const [image] = useImage(editorData?.image?.url || "", "anonymous")

  const downloadURI = (uri: string, name: string) => {
    const link = document!.createElement("a")
    link.download = name
    link.href = uri

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const resizeImage = (
    url: string,
    width: number,
    height: number,
    callback: (value: string) => void
  ) => {
    var sourceImage = new Image()

    sourceImage.onload = function () {
      var canvas = document.createElement("canvas")
      canvas.width = width
      canvas.height = height
      // @ts-ignore
      canvas.getContext("2d").drawImage(sourceImage, 0, 0, width, height)
      callback(canvas.toDataURL())
    }

    sourceImage.src = url
  }

  const handleDownload = () => {
    // @ts-ignore
    const uri = stageRef?.current?.toDataURL({ pixelRatio: 4 })

    if (uri && selectedFrame?.originalSize) {
      resizeImage(
        uri,
        selectedFrame.originalSize.width,
        selectedFrame.originalSize.height,
        (sizeUpdatedDataURL: string) => {
          downloadURI(sizeUpdatedDataURL, "eu_sou_peixe30.png")
        }
      )
    }
  }

  const handleViewPreviewToggle = () => {
    setViewPreview((currentState) => !currentState)
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: {
            xs: "flex",
            sm: "none",
          },
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{
            background: colors.grayScale[1],
            color: "#f1f1f1 !important",
            textTransform: "inherit",
            fontSize: "calc(1rem * 0.8)",
            borderRadius: "16px",
            "&:hover": {
              background: "rgb(83, 88, 96)",
            },
          }}
          onClick={handleViewPreviewToggle}
        >
          Visualizar {viewPreview ? "Editor" : "Preview"}
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        sm={5}
        sx={{
          padding: {
            xs: "4px 6px",
            sm: "8px 16px",
          },
          display: {
            xs: viewPreview ? "none" : "block",
            sm: "block",
          },
        }}
      >
        <Grid container width="100%" height="100%" spacing={{ xs: 1, sm: 4 }}>
          <Grid
            item
            xs={12}
            sx={{
              flex: 1,
            }}
          >
            <Typography
              component="p"
              variant="caption"
              sx={{
                color: colors.grayScale[1],
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                },
              }}
            >
              Editor
            </Typography>

            <Divider size={3} orientation="horizontal" />

            <FrameGeneratorEditor
              canInsertText={canInsertText}
              recommendedDimensions={selectedFrame?.recommendedDimensions}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              alignContent="flex-end"
              height="100%"
            >
              <Grid item xs={10}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleDownload}
                  sx={{
                    maxWidth: 450,
                    color: colors.grayScale[11],
                    margin: {
                      xs: "16px 0px",
                      sm: "0px",
                    },
                  }}
                >
                  Salvar imagem
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={7}
        sx={{
          display: {
            xs: viewPreview ? "block" : "none",
            sm: "block",
          },
        }}
      >
        <Typography
          component="p"
          variant="caption"
          sx={{
            color: colors.grayScale[1],
            fontSize: {
              xs: "12px",
              sm: "14px",
            },
          }}
        >
          Preview
        </Typography>

        <Divider size={3} orientation="horizontal" />

        {typeof window !== "undefined" && selectedFrame ? (
          <Portlet style={{ background: defaultBackground }}>
            <CanvasStage
              stageRef={stageRef}
              frameImg={frameImg}
              image={image}
              height={editorData?.image?.height || 300}
              width={editorData?.image?.width || 300}
              resize={editorData?.image?.resize || selectedFrame?.defaultResize}
              stageDimensions={
                isSmallDevice
                  ? selectedFrame.stageDimensions?.xs
                  : selectedFrame.stageDimensions.sm
              }
              imageDimensions={
                isSmallDevice
                  ? selectedFrame.imageDimensions?.xs
                  : selectedFrame.imageDimensions.sm
              }
              imagePosition={
                isSmallDevice
                  ? selectedFrame.imagePosition.xs
                  : selectedFrame.imagePosition.sm
              }
              groupDimensions={
                isSmallDevice
                  ? selectedFrame.groupDimensions.xs
                  : selectedFrame.groupDimensions.sm
              }
              transformablePosition={
                isSmallDevice
                  ? selectedFrame?.transformablePosition.xs
                  : selectedFrame?.transformablePosition.sm
              }
              extraData={{
                ...(editorData?.text && {
                  text: editorData.text,
                }),
              }}
            />
          </Portlet>
        ) : (
          "Não foi possível carregar o componente..."
        )}

        <Divider size={3} orientation="horizontal" />

        <Grid container spacing={2} justifyContent="center">
          {frameOptions.length > 1 &&
            frameOptions.map((frameOption, index) => {
              if (!frameOption?.url) return null
              return (
                <Grid key={index} item>
                  <Portlet
                    style={{
                      borderRadius: 0,
                      background: defaultBackground,
                      height: "56px",
                      width: "56px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "background 0.8s",
                      border: `${
                        frameOption.url !== selectedFrame?.url
                          ? "2px solid transparent"
                          : `2px solid ${colors.palette.primary}`
                      }`,
                      "&:hover": {
                        opacity: 0.8,
                        border: `${
                          frameOption.url === selectedFrame?.url
                            ? "2px solid transparent"
                            : `2px solid ${colors.palette.primary}`
                        }`,
                      },
                    }}
                    onClick={() => setSelectedFrame(frameOption)}
                  >
                    <Box
                      component="img"
                      src={frameOption.url}
                      sx={{
                        height: "40px",
                        width: "auto",
                      }}
                    />
                  </Portlet>
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    </Grid>
  )
}

const FrameGeneratorWithContext = (props: FrameGeneratorProps) => (
  <FrameGeneratorProvider>
    <FrameGenerator {...props} />
  </FrameGeneratorProvider>
)
export default FrameGeneratorWithContext
