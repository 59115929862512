import { useState } from "react"

import { Stage, Layer, Image, Group } from "react-konva"
import TransformableText from "~elements/canvas/transformableText.component"
import TransformableImage from "~elements/canvas/transformableImage.component"

import {
  TextEditorProps,
  IPosition,
  IDimensions,
} from "~interfaces/frameGenerator.interface"
import { RenderImageProps } from "~interfaces/canvas.interface"

interface CanvasStageProps {
  stageRef: any
  frameImg: HTMLImageElement | undefined
  image: HTMLImageElement | undefined
  height: number
  width: number
  stageDimensions: IDimensions
  imageDimensions: IDimensions
  groupDimensions: IDimensions

  imagePosition: IPosition
  transformablePosition: IPosition

  resize?: number
  extraData?: {
    text?: TextEditorProps | undefined
  }
}

const CanvasStage = (props: CanvasStageProps) => {
  const {
    stageRef,
    frameImg,
    image,
    height,
    width,
    resize,

    stageDimensions,
    imageDimensions,
    groupDimensions,
    imagePosition,
    transformablePosition,
    extraData,
  } = props

  const referenceSize = height > width ? "height" : "width"

  /**
   * TODO
   * Separate this code into a UTIL file
   * to make the code more readable and reusable
   * */
  let imageRenderWidth =
    groupDimensions?.width > width ? width : groupDimensions?.width
  let imageRenderHeight =
    groupDimensions?.height > height ? height : groupDimensions?.height

  if (referenceSize === "width") {
    let percentageWidth =
      imageRenderWidth > width ? 1 : (imageRenderWidth * 100) / width
    percentageWidth = percentageWidth / 100

    imageRenderHeight = height * percentageWidth
  } else {
    let percentageHeight =
      imageRenderHeight > height ? 1 : (imageRenderHeight * 100) / height
    percentageHeight = percentageHeight / 100

    imageRenderWidth = width * percentageHeight
  }

  imageRenderWidth = imageRenderWidth * (resize || 1)
  imageRenderHeight = imageRenderHeight * (resize || 1)

  const rect = [
    {
      x: 50,
      y: 50,
      id: "rect1",
    },
    {
      x: 100,
      y: 100,
      id: "rect2",
    },
  ]

  const renderImg = [
    {
      x: imagePosition?.x,
      y: imagePosition?.y,
      id: "renderImg1",
    },
  ]

  const [tranImg, setTranImg] = useState<RenderImageProps[]>(renderImg)
  const [selectedId1, selectShape1] = useState<string | null>(null)
  const [rectangles, setRectangles] = useState<RenderImageProps[]>(rect)
  const [selectedId, selectShape] = useState<string | null>(null)

  const checkDeselect = () => {
    selectShape1(null)
    selectShape(null)
  }

  return (
    <Stage
      ref={stageRef as any}
      width={stageDimensions.width}
      height={stageDimensions.height}
      x={0}
      style={{ margin: "auto", width: "fit-content" }}
    >
      <Layer>
        <Group
          clipX={imagePosition?.x}
          clipY={imagePosition?.y}
          clipWidth={groupDimensions.width}
          clipHeight={groupDimensions.height}
        >
          <TransformableImage
            image={image}
            transformablePosition={transformablePosition}
            imageWidth={imageRenderWidth}
            imageHeight={imageRenderHeight}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
            isSelected={renderImg[0].id === selectedId1}
            onSelect={() => {
              selectShape1(renderImg[0].id)
            }}
            onChange={(newAttrs: RenderImageProps) => {
              const imgs = tranImg.slice()
              imgs[0] = newAttrs
              setTranImg(imgs)
            }}
            shapeProps={undefined}
          />

          {extraData?.text?.value && (
            <TransformableText
              transformablePosition={transformablePosition}
              shapeProps={rect[0]}
              isSelected={rect[0].id === selectedId}
              textValue={extraData.text?.value}
              fontColor={extraData.text?.color}
              fontStyle="bold"
              fontSize={14}
              onSelect={() => {
                selectShape(rect[0].id || null)
              }}
              onChange={(newAttrs) => {
                const rects = rectangles.slice()
                rects[0] = newAttrs
                setRectangles(rects)
              }}
            />
          )}
        </Group>

        <Image
          image={frameImg as any}
          width={imageDimensions.width}
          height={imageDimensions.height}
          style={{
            zIndex: "100",
            position: "absolute",
          }}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
          listening={false}
        />
      </Layer>
    </Stage>
  )
}

export default CanvasStage
