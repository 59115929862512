import { useState } from "react"
import {
  Container,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Popover,
  Typography,
  Box,
  Stack,
  Slider,
} from "@mui/material"
import { ChromePicker } from "react-color"
import {
  Edit as TextIcon,
  ColorLens as ColorIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material"

import { Divider, InputImage } from "~elements/index"
import { useFrameGeneratorStore } from "~stores/frameGenerator.store"
import {
  ImageEditorProps,
  TextEditorProps,
} from "~interfaces/frameGenerator.interface"

interface FrameGeneratorEditorProps {
  recommendedDimensions?: string
  canInsertText?: boolean
}

const FrameGeneratorEditor = (props: FrameGeneratorEditorProps) => {
  const { canInsertText, recommendedDimensions } = props
  const { editorData, setImageEditorData, setTextEditorData } =
    useFrameGeneratorStore()

  const imageResize = editorData?.image?.resize

  const [textColorPopoverEl, setTextColorPopoverEl] =
    useState<HTMLButtonElement | null>(null)

  const textColorPopoverId = "text-color-popover"

  const handleTextColorChange = (
    event?: React.MouseEvent<HTMLButtonElement> | null
  ) => {
    setTextColorPopoverEl(event?.currentTarget || null)
  }

  const handleImageEditorChange = (data: ImageEditorProps) => {
    setImageEditorData(data)
  }

  const handleTextEditorChange = (data: TextEditorProps) => {
    setTextEditorData(data)
  }

  const handleResizeImage = (newValue: number | number[]) => {
    if (typeof newValue === "number") {
      if (newValue < 2 || newValue > 0.25) {
        handleImageEditorChange({
          resize: newValue,
        })
      }
    }
  }

  return (
    <Container>
      <Grid container justifyContent="center">
        <InputImage onChange={handleImageEditorChange} />

        <Divider size={1} orientation="horizontal" />

        <Typography
          variant="caption"
          width="100%"
          maxWidth="260px"
          sx={{
            fontSize: "calc(0.6 * 1rem)",
          }}
        >
          *Recomendado: {recommendedDimensions}
        </Typography>

        <Divider size={2.5} orientation="horizontal" />

        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "80%",
            },
          }}
        >
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <IconButton
              disabled={
                !editorData?.image?.url ||
                Boolean(imageResize && imageResize <= 0.25)
              }
              aria-label="decrease size"
              onClick={() => handleResizeImage((imageResize || 1) - 0.25)}
            >
              <RemoveIcon sx={{}} />
            </IconButton>
            <Slider
              value={imageResize || 1}
              step={0.25}
              min={0.1}
              max={2}
              marks
              onChange={(e, newValue) => handleResizeImage(newValue)}
              aria-label="Resize transformable - image"
              disabled={!editorData?.image?.url}
            />
            <IconButton
              disabled={
                !editorData?.image?.url ||
                Boolean(imageResize && imageResize >= 2)
              }
              aria-label="increase size"
              onClick={() => handleResizeImage((imageResize || 1) + 0.25)}
            >
              <AddIcon sx={{}} />
            </IconButton>
          </Stack>
        </Box>
      </Grid>

      <Divider size={3} orientation="horizontal" />

      {canInsertText && (
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <TextField
              id="input-text-frame-generator"
              label="Inserir rede social"
              placeholder="Ex.: @peixe30.oficial"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TextIcon />
                  </InputAdornment>
                ),
              }}
              onChange={({ target }) =>
                handleTextEditorChange({ value: target.value })
              }
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              aria-describedby={textColorPopoverId}
              size="medium"
              aria-label="alterar cor do texto"
              component="span"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleTextColorChange(e)
              }
            >
              <ColorIcon />
            </IconButton>

            <Popover
              id={textColorPopoverId}
              open={Boolean(textColorPopoverEl)}
              anchorEl={textColorPopoverEl}
              onClose={() => handleTextColorChange(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <ChromePicker
                color={editorData?.text?.color ?? "#fff"}
                onChange={(color) =>
                  handleTextEditorChange({ color: color.hex })
                }
              />
            </Popover>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default FrameGeneratorEditor
