import AvatarFrame from "~images/frames/avatar_i_am_peixe30.png"
import FeedFrame from "~images/frames/feed_i_am_peixe30.png"
import StorieFrame from "~images/frames/storie_i_am_peixe30.png"

import { IFrameOption } from "~interfaces/frameGenerator.interface"

interface IFrameData {
  [key: string]: IFrameOption
}

const FrameData: IFrameData = {
  avatar: {
    url: AvatarFrame,
    recommendedDimensions: "1080px X 1080px (altura x largura)",
    defaultResize: 1.25,
    originalSize: {
      width: 1080,
      height: 1080
    },
    stageDimensions: {
      xs: {
        height: 230,
        width: 230,
      },
      sm: {
        height: 390,
        width: 390
      }
    },
    imageDimensions: {
      xs: {
        height: 230,
        width: 230,
      },
      sm: {
        height: 390,
        width: 390
      }
    },
    groupDimensions: {
      xs: {
        height: 161,
        width: 161,
      },
      sm: {
        height: 271,
        width: 271
      }
    },
    imagePosition: {
      xs: {
        x: 35,
        y: 34,
      },
      sm: {
        x: 59,
        y: 58,
      }
    },
    transformablePosition: {
      xs: {
        x: 35,
        y: 34,
      },
      sm: {
        x: 50,
        y: 50,
      }
    }
  },
  feed: {
    url: FeedFrame,
    recommendedDimensions: "520px X 520px (altura x largura)",
    originalSize: {
      width: 1080,
      height: 1080
    },
    stageDimensions: {
      xs: {
        height: 270,
        width: 270,
      },
      sm: {
        height: 400,
        width: 400
      }
    },
    imageDimensions: {
      xs: {
        height: 270,
        width: 270,
      },
      sm: {
        height: 400,
        width: 400
      }
    },
    groupDimensions: {
      xs: {
        height: 210,
        width: 210,
      },
      sm: {
        height: 260,
        width: 260
      }
    },
    imagePosition: {
      xs: {
        x: 90,
        y: 0
      },
      sm: {
        x: 150,
        y: 20
      }
    },
    transformablePosition: {
      xs: {
        x: 150,
        y: 80
      },
      sm: {
        x: 190,
        y: 80
      }
    }
  },
  storie: {
    url: StorieFrame,
    recommendedDimensions: "572px X 572px (altura x largura)",
    originalSize: {
      width: 1080,
      height: 1920
    },
    stageDimensions: {
      xs: {
        height: 270,
        width: 180,
      },
      sm: {
        height: 390,
        width: 240
      }
    },
    imageDimensions: {
      xs: {
        height: 270,
        width: 180,
      },
      sm: {
        height: 390, // 
        width: 240, // 
      }
    },
    groupDimensions: {
      xs: {
        height: 103.5,
        width: 105,
      },
      sm: {
        height: 150,
        width: 140,
      }

    },
    imagePosition: {
      xs: {
        x: 21, // 30
        y: 67
      },
      sm: {
        x: 30,
        y: 90
      }
    },
    transformablePosition: {
      xs: {
        x: 7, // 30
        y: 60
      },
      sm: {
        x: 10,
        y: 80
      }
    }
  }
}

export default FrameData