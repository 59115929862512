import { Button, Grid, Typography } from "@mui/material"
import { Apple as AppleIcon, Shop as GoogleIcon } from "@mui/icons-material"
import colors from "~styles/colors"

interface DownloadAppButtonProps {
  type: "ios" | "android"
}
const typeOptions = {
  ios: {
    storeName: "App Store",
    downloadUrl: "https://apps.apple.com/br/app/peixe-30/id1519304600",
    icon: (
      <AppleIcon
        color="inherit"
        sx={{
          fontSize: "28px !important",
        }}
      />
    ),
  },
  android: {
    storeName: "Google Play",
    downloadUrl: "https://play.google.com/store/apps/details?id=com.peixe30",
    icon: (
      <GoogleIcon
        color="inherit"
        sx={{
          fontSize: "28px !important",
        }}
      />
    ),
  },
}

const DownloadAppButton = (props: DownloadAppButtonProps) => {
  const { type } = props

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={typeOptions[type].icon}
      target="_blank"
      href={typeOptions[type].downloadUrl}
      sx={{
        background: colors.grayScale[1],
        color: "#f1f1f1 !important",
        textTransform: "inherit",
        "&:hover": {
          background: "rgb(83, 88, 96)",
        },
      }}
    >
      <Grid
        container
        flexDirection="column"
        sx={{
          textAlign: "start",
          marginLeft: "4px",
        }}
      >
        <Grid item>
          <Typography variant="caption" color="inherit">
            Disponível na
          </Typography>
        </Grid>

        <Grid item xs>
          <Typography color="inherit">{typeOptions[type].storeName}</Typography>
        </Grid>
      </Grid>
    </Button>
  )
}

export default DownloadAppButton
