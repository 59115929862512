import React, { useEffect, useRef } from "react"

// Components
import { Image, Transformer } from "react-konva"
import { RenderImageProps } from "~interfaces/canvas.interface"
import { IPosition } from "~interfaces/frameGenerator.interface"

interface IProps {
  image: HTMLImageElement | undefined
  shapeProps?: RenderImageProps
  isSelected: boolean

  imageWidth: number
  imageHeight: number

  transformablePosition: IPosition

  onMouseDown: () => void
  onTouchStart: () => void
  onSelect: () => void
  onChange: (value: RenderImageProps) => void
}

const TransformableImage = (props: IProps) => {
  const {
    shapeProps,
    isSelected,
    imageWidth,
    imageHeight,
    image,
    transformablePosition,

    onMouseDown,
    onTouchStart,
    onSelect,
    onChange,
  } = props

  const shapeRef = useRef<any>()
  const trRef = useRef<any>()

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  return (
    <>
      <Image
        image={image}
        height={imageHeight}
        width={imageWidth}
        x={transformablePosition.x}
        y={transformablePosition.y}
        onClick={onSelect}
        onTap={onSelect}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          })
        }}
        onTransformEnd={() => {
          if (shapeRef.current) {
            const node = shapeRef.current
            onChange({
              ...shapeProps,
              x: node.x(),
              y: node.y(),
            })
          }
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
    </>
  )
}

export default TransformableImage
