import { createTheme } from '@mui/material/styles'
import colors from "~styles/colors"

const defaultTheme = createTheme()

const theme = createTheme({
  palette: {
    background: {
      default: colors.palette.background,
      paper: colors.grayScale[11]
    },
    primary: {
      main: colors.palette.primary
    },
    secondary: {
      main: colors.palette.secondary,
      contrastText: "#FFF"
    },
    info: {
      main: colors.palette.info
    },
    warning: {
      main: colors.palette.warning
    },
    success: {
      main: colors.palette.confirm
    },
    text: {
      primary: colors.grayScale[4]
    },
  },
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "calc(.95rem * 2.5)",
      lineHeight: "42px",
      color: colors.grayScale[1]
    },
    h2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "calc(.95rem * 2)",
      lineHeight: "30px",
      color: colors.grayScale[1]
    },
    h3: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "calc(.95rem * 1.75)",
      lineHeight: "24px",
      color: colors.grayScale[1]
    },
    h4: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "calc(.95rem * 1.5)",
      lineHeight: "24px",
      color: colors.grayScale[4]
    },
    h5: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "calc(.95rem * 1.25)",
      lineHeight: "16px"
    },
    h6: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "calc(.95rem * 1.1)",
      lineHeight: "24px"
    },
    body1: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "calc(.95rem * 1.1)",
      lineHeight: "24px",
      color: colors.grayScale[4]
    },
    body2: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "calc(.95rem * 1.0)",
      lineHeight: "24px",
      color: colors.grayScale[4]
    },
    overline: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      textTransform: "none",
      color: colors.grayScale[4]
    },
    caption: {
      fontSize: "calc(.95rem * 0.75)",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "normal",
      lineHeight: "16px",
      color: colors.grayScale[3]
    },
    button: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: "125%"
    }
  },
  components: {
  },
  shadows: Object.values({
    ...defaultTheme.shadows,
    0: "0px 0px 0px rgba(0, 0, 0, 0)",
    1: "0px 2px 4px rgba(33, 36, 41, 0.08)"
    // eslint-disable-next-line
  }) as any
})

export default theme