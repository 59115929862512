import { useState } from "react"
import { Avatar, Button, Container, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"

import {
  Divider,
  Dialog as DialogComponent,
  DownloadAppButton,
} from "~elements/index"

import FrameData from "~configs/frameData.config"
import Peixe30Logo from "~images/peixe30_logo.png"
import Peixe30LogoWhite from "~images/peixe30_logo_white.png"

import { FrameGenerator } from "~modules/index"
import colors from "~styles/colors"

interface IOpenDialog {
  avatar: boolean
  post: boolean
}

const Home = () => {
  const [openDialog, setOpenDialog] = useState<IOpenDialog>({
    avatar: false,
    post: false,
  })

  const toggleDialog = (type: keyof IOpenDialog) => {
    setOpenDialog((currentState) => ({
      ...currentState,
      [type]: !currentState[type],
    }))
  }

  return (
    <>
      <Container
        component="header"
        sx={{
          position: "absolute",
          padding: "24px 0px",
        }}
      >
        <Grid
          container
          component="section"
          className="section__content"
          maxWidth="lg"
          justifyContent="center"
          spacing={4}
          textAlign="center"
        >
          <img src={Peixe30LogoWhite} alt="Logo peixe30 - branco" />
        </Grid>
      </Container>

      <Box
        component="main"
        sx={{
          background:
            "rgb(255,255,255) url('https://cdn.greatsoftwares.com.br/arquivos/paginas_publicadas/sereducacional.great.app.br-soupeixe30/imagens/desktop/1651173377-730591-7461-5fd0301ee329970aed48a74752481db7.png')",
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container
          component="section"
          maxWidth="lg"
          sx={{
            marginTop: "72px",
          }}
        >
          <Grid
            container
            alignItems={"center"}
            className="section__content"
            spacing={4}
          >
            <Grid item xs={12} md={6}>
              <Typography
                component="h2"
                variant="h2"
                fontWeight={500}
                color="white"
                sx={{
                  b: {
                    color: colors.palette.primary,
                  },
                }}
              >
                A rede profissional que te <br />
                <b>conecta com o mercado.</b>
              </Typography>

              <Divider size={4} orientation="horizontal" />

              <Typography
                component="p"
                variant="body1"
                fontWeight={500}
                color="white"
                sx={{
                  b: {
                    color: colors.palette.primary,
                  },
                }}
              >
                Encontre as oportunidades certas
                <br /> gravando <strong>vídeos de 30 segundos</strong> e
                <br /> venda seu melhor produto: <b>você</b>.
              </Typography>

              <Divider size={5} orientation="horizontal" />

              <Typography
                component="p"
                variant="h3"
                fontWeight={600}
                color="primary"
                textTransform="uppercase"
              >
                Baixe agora o APP
              </Typography>

              <Divider size={3} orientation="horizontal" />

              <Grid container spacing={1}>
                <Grid item sx={{ margin: { xs: "0 auto", sm: "0" } }}>
                  <DownloadAppButton type="ios" />
                </Grid>
                <Grid item sx={{ margin: { xs: "0 auto", sm: "0" } }}>
                  <DownloadAppButton type="android" />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Avatar
                    alt="Carregar minha foto"
                    src={FrameData["avatar"].url}
                    sx={{
                      margin: "0 auto",
                      boxShadow: "0px 0px 9px #ffffff5c",
                      width: {
                        xs: 290,
                        sm: 360,
                      },
                      height: {
                        xs: 290,
                        sm: 360,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    sx={{
                      display: "flex",
                      margin: "0 auto",
                      color: colors.grayScale[11],
                      padding: "14px 20px",
                    }}
                    variant="contained"
                    onClick={() => toggleDialog("avatar")}
                    size={"large"}
                  >
                    Carregar minha foto
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>

        <Container component="section" maxWidth="lg">
          <Grid
            container
            alignItems={"center"}
            spacing={4}
            className="section__content"
          >
            <Grid item xs={12}>
              <Box
                component="img"
                sx={{
                  boxShadow: "0px 0px 9px #ffffff5c",
                  width: "auto",
                  height: {
                    xs: 310,
                    sm: 610,
                  },
                  margin: "0 auto",
                  display: "flex",
                }}
                src={FrameData["feed"].url}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                sx={{
                  display: "flex",
                  margin: "0 auto",
                  color: colors.grayScale[11],
                  padding: "14px 20px",
                }}
                variant="contained"
                onClick={() => toggleDialog("post")}
                size={"large"}
              >
                Carregar minha foto
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container
        component="footer"
        sx={{
          background: "rgb(255,255,255)",
        }}
      >
        <Grid
          container
          component="section"
          className="section__content"
          maxWidth="lg"
          justifyContent="center"
          spacing={4}
          textAlign="center"
        >
          <Grid item xs={12}>
            <Box
              component="img"
              src={Peixe30Logo}
              sx={{
                width: "100%",
                maxWidth: "290px",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <Typography
              color={colors.grayScale[5]}
              component="p"
              variant="h3"
              textAlign="center"
            >
              A rede profissional da nova economia que conecta pessoas e
              empresas com vontade de crescer.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              color="primary"
              component="p"
              variant="h3"
              fontWeight={600}
            >
              BAIXE O APP
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <DownloadAppButton type="ios" />
              </Grid>

              <Grid item>
                <DownloadAppButton type="android" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <DialogComponent
        isOpen={Boolean(openDialog?.avatar)}
        onClose={() => toggleDialog("avatar")}
        maxWidth={"xl"}
      >
        <FrameGenerator frameOptions={[FrameData["avatar"]]} />
      </DialogComponent>

      <DialogComponent
        isOpen={Boolean(openDialog?.post)}
        onClose={() => toggleDialog("post")}
        maxWidth={"xl"}
      >
        <FrameGenerator
          frameOptions={[FrameData["feed"], FrameData["storie"]]}
          canInsertText
        />
      </DialogComponent>
    </>
  )
}

export default Home
