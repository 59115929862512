import { createContext, useContext, useState } from "react"
import {
  EditorProps,
  ImageEditorProps,
  TextEditorProps,
} from "~interfaces/frameGenerator.interface"

interface FrameGeneratorProviderProps {
  children?: React.ReactNode
}

export interface FrameGeneratorContextData {
  editorData: EditorProps | null
  setEditorData: (data: Partial<EditorProps>) => void

  setImageEditorData: (data: Partial<ImageEditorProps>) => void
  setTextEditorData: (data: Partial<TextEditorProps>) => void

  clearData: () => void
}

const FrameGenerator = createContext<FrameGeneratorContextData>(
  {} as FrameGeneratorContextData
)

export const useFrameGeneratorStore = (): FrameGeneratorContextData =>
  useContext(FrameGenerator)

const FrameGeneratorProvider = (props: FrameGeneratorProviderProps) => {
  const { children } = props

  const [editorData, setEditor] = useState<EditorProps | null>(null)

  const setEditorData = (data: Partial<EditorProps>) => {
    setEditor((currentState) => ({
      ...currentState,
      ...data,
    }))
  }

  const setImageEditorData = (data: Partial<ImageEditorProps>) => {
    setEditorData({
      image: {
        ...(editorData?.image && { ...editorData?.image }),
        ...data,
      },
    })
  }

  const setTextEditorData = (data: Partial<TextEditorProps>) => {
    setEditorData({
      text: {
        ...(editorData?.text && { ...editorData?.text }),
        ...data,
      },
    })
  }

  const clearData = () => {
    setEditorData({})
  }

  FrameGenerator.displayName = "frame_generator"

  return (
    <FrameGenerator.Provider
      value={{
        editorData,
        setEditorData,

        setImageEditorData,
        setTextEditorData,

        clearData,
      }}
    >
      {children}
    </FrameGenerator.Provider>
  )
}

export default FrameGeneratorProvider
